<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    item: Object,
    depth: Number,
    index: String,
    category: Boolean,
  });

  const component = computed(() => {
    if (props.item.url) {
      return 'VisitorLink';
    } else {
      return 'span';
    }
  });

  const specific = computed(() => {
    if (props.item.url) {
      return {
        href: props.item.url,
        rel: props.item.rel,
        target: props.item.target,
        explicit: props.item.explicit,
      };
    } else {
      return {};
    }
  });

  const level = computed(() => {
    return `level-${props.depth}`;
  });

  const children = computed(() => {
    return `nav-children-${props.depth}-${props.index}`;
  });
</script>
<template>
  <li class="nav-item" :class="level" @click="$emit('close')">
    <component :is="component" v-bind="specific" :class="{ category }" tabindex="0" aria-haspopup="true" :aria-controls="children">
      <span class="nav-item__unit">
        <span v-if="item.icon" v-html="item.icon" class="nav-item__icon" />
        {{ item.name }}
        <small class="category-available" v-if="depth !== 0 && item.available">
          ({{ item.available }})
        </small>
      </span>
      <IconChevronRight v-if="depth === 0 && item.children.length > 0" class="nav-item-arrow" />
    </component>
    <div v-if="category || item.children.length > 0" :id="children" class="children-wrapper">
      <ul class="children">
        <HeaderNavItem
          v-for="(item, i) in item.children"
          :item="item"
          :depth="depth + 1"
          :index="`${index}-${i}`"
          @close="$emit('close')"
        />
      </ul>
    </div>
  </li>
</template>
<style scoped lang="scss">
  .nav-item {
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .category-available {
      color: var(--color-secondary);
      font-size: 0.75rem;
      line-height: 1.5;
      font-weight: var(--font-weight-regular);
      padding-left: 0.5rem;
      transition: var(--primary-transition);
    }

    &:hover, &:focus-within {
      &.level-0 > .children-wrapper {
        display: block;
        border-bottom: 1px solid var(--color-grey);
        border-right: 1px solid var(--color-grey);
      }
    }

    &.level-2 {
      font-size: 0.875rem;

      .children-wrapper {
        display: none;
      }
    }

    a {
      display: block;
      padding: 0.375rem 0;
      transition-property: color, background-color;
      transition-duration: var(--transition-primary-duration);
      transition-timing-function: var(--transition-primary-timing-function);

      &:focus {
        color: var(--primary-color);
      }

      @media(hover: hover) {
        &:hover,
        &:hover .category-title .category-available {
          color: var(--primary-color);
        }
      }
    }

    &.level-0 {
      > .children-wrapper {
        position: absolute;
        display: none;
        top: 0;
        left: 299px;
        right: 0;
        height: 100%;
        padding: 0.875rem 1rem 1rem 2rem;
        background-color: var(--secondary-background);

        > .children {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: auto;
          gap: 2rem 1rem;
          max-height: 100%;
          overflow-y: auto;
          overscroll-behavior: contain;
          list-style: none;
          margin: 0;

          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-track {
            background-color: var(--color-light-background);
            border-radius: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: var(--primary-color);
            border-radius: 4px;
          }

          @media(max-width: 1650px) {
            grid-template-columns: repeat(3, 1fr);
          }

          @media(max-width: 1200px) {
            grid-template-columns: repeat(2, 1fr);
          }

          & > li > a {
            font-weight: var(--font-weight-bold);
            font-size: 1rem;
            line-height: 1.5;
            padding: 0.5rem 0;
          }
        }
      }

      > a {
        position: relative;

        &.category {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          font-weight: var(--font-weight-bold);
          padding: 0.75rem 0 0.75rem 1.25rem;
        }

        & svg {
          transform: translateX(-0.5rem);
          font-size: 0.875rem;
          transition: var(--primary-transition);
        }
      }

      @media(hover: hover) {
        &:hover > a.category {
          color: var(--primary-color);
          background-color: var(--secondary-background);

          > svg {
            transform: translateX(0);
          }
        }
      }
    }

    ul {
      list-style: none;
    }
  }
</style>
