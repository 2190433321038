<script lang="ts" setup>
  import { useShared } from '@plenny/visitor';
  import { trans } from '@plenny/translator';

  const shared = useShared();

  const days = {
    mon: trans('poniedziałek'),
    tue: trans('wtorek'),
    wed: trans('środa'),
    thu: trans('czwartek'),
    fri: trans('piątek'),
    sat: trans('sobota'),
    sun: trans('niedziela'),
  };
</script>
<template>
  <footer class="footer">
    <div class="container footer__top">
      <div class="footer__info">

        <div class="footer__block footer__data">
          <SfLogo />
          <a :href="shared.department.maps || '#'" class="address-link" target="_blank">
            <span v-if="shared.department.first_line" v-text="shared.department.first_line" />
            <span v-if="shared.department.second_line" v-text="shared.department.second_line" />
            <span v-if="shared.department.third_line" v-text="shared.department.third_line" />
          </a>
        </div>

        <div v-if="shared.department" class="footer__block">
          <SfHeading large class="footer__block-heading">{{ $t('Kontakt') }}</SfHeading>
          <div class="footer__contact">
            <a v-if="shared.department.phone" :href="`tel:${shared.department.phone}`" class="footer__link">
              <IconPhone />
              <span v-html="shared.department.phone" />
            </a>
            <a v-if="shared.department.email" :href="`mailto:${shared.department.email}`" class="footer__link">
              <IconEnvelope />
              <span v-html="shared.department.email" />
            </a>
          </div>
        </div>

        <div v-for="group in shared.menu.menu_bottom" class="footer__block">
          <SfHeading large>{{ group.name }}</SfHeading>
          <ul>
            <li v-for="item in group.children">
              <VisitorLink
                :explicit="item.explicit"
                :href="item.url"
                :rel="item.rel"
                :target="item.target"
                class="footer__link">
                {{ item.name }}
              </VisitorLink>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer__newsletter">
        <Newsletter />
      </div>
    </div>
    <div class="footer__bottom">
      <div class="container footer__bottom-content">
        <div>
          <CopyrightMessage />
        </div>
        <div>
          <p>
            {{ $t('Zrealizowane przez') + ': ' }}<a class="bottom-link" href="https://promoznawcy.pl/" target="_blank">PROMOznawcy.pl</a>
          </p>
        </div>
      </div>
    </div>
    <FixedActions />
  </footer>
</template>
<style lang="scss" scoped>
  .footer {
    background-color: var(--secondary-border);
    margin-top: 5rem;
    background-image: url("../../../assets/images/hvac-pipes.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    &__block {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      font-weight: var(--font-weight-bold);

      ul {
        list-style: none;

        a {
          display: inline-flex;
          padding: 0.5rem 0;
          line-height: 1;
        }
      }
    }

    &__top {
      display: grid;
      grid-template-columns: 2fr 1fr;

    }

    &__info {
      position: relative;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 3rem;
      padding: 5rem 5rem 5rem 0;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        width: 100vw;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(230, 239, 242, 0.85);
        z-index: -1;
      }
    }

    &__newsletter {
      position: relative;
      padding: 5rem 0 5rem 5rem;
      z-index: 1;
      --sf-heading-color: var(--color-white);

      &::before {
        content: "";
        position: absolute;
        width: 100vw;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(2, 30, 46, 0.85);
        z-index: -1;
      }
    }

    &__contact {
      display: flex;
      flex-direction: column;

      a {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 0;
      }
    }

    &__bottom {
      background-color: #086492;
    }

    &__bottom-content {
      display: flex;
      justify-content: space-between;
      color: var(--color-white);
      font-size: 0.875rem;
      padding: 1rem 0;

      a {
        color: var(--color-white);

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }


  @media(max-width: 1200px) {
    .footer {
      &__info {
        grid-template-columns: 1fr 1fr;
        padding-right: 3rem
      }

      &__newsletter {
        padding-left: 3rem;
      }
    }
  }

  @media(max-width: 992px) {

    .footer {
      margin-top: 3rem;

      &__top {
        display: flex;
        flex-direction: column-reverse;
      }

      &__info {
        grid-template-columns: 1fr;
        padding: 3rem 1rem;
      }

      &__newsletter {
        padding: 3rem 1rem;
      }

      &__block {
        gap: 1rem;
      }

      &__link {
        font-size: 0.875rem
      }

      &__bottom-content {
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      }
    }

    main.contact ~ .footer {
      margin-top: 0;
    }
  }
</style>
