<script setup lang="ts">
  import { useBreakpoints } from '$storefront';

  const breakpoints = useBreakpoints();

  defineProps({
    title: { type: String, required: false },
    link: { type: String, required: false },
    buttonText: { type: String, required: false },
  });
</script>
<template>
  <section class="wrapper section-wrapper">
    <div v-if="title || link" class="section-header">
      <div class="section-header-inner">
        <SfHeading v-if="title" large type="h2">
          {{ title }}
        </SfHeading>
        <SfButton v-if="link" class="btn-custom" :href="link">
          {{ buttonText || $t('Przejdź') }}
        </SfButton>
      </div>
    </div>
    <div class="section-content">
      <slot />
    </div>
  </section>
</template>
<style scoped lang="scss">
  .section-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .section-header .section-header-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 3rem 5rem 0;
      color: var(--primary-color-hover);
      border-top: 1px solid var(--secondary-border);
    }

    .section-content {
      margin-top: 40px;
    }

    &:has(.categories-carousel) {
      border-top: none;

      .section-content {
        margin-top: 0;
      }
    }
  }

  @media(max-width: 992px) {
    .section-wrapper {

      .section-header {
        padding: 0 1rem;

        .section-header-inner {
          padding: 1rem 0;
        }
      }

      .section-content {
        margin-top: 0;
      }
    }
  }
</style>
