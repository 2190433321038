<script lang="ts" setup>
  import { ref } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: { type: String },
    content: { type: String },
    products: { type: Array },
  });

  const parameters = ref({
    spaceBetween: 20,
    breakpoints: {
      1: {
        slidesPerView: 1.5,
        slidesOffsetBefore: 16,
      },
      576: {
        slidesPerView: 2.5,
        slidesOffsetBefore: 16,
      },
      768: {
        slidesPerView: 3.5,
        slidesOffsetBefore: 16,
      },
      992: {
        slidesPerView: 4,
        slidesOffsetBefore: 0,
      },
    },
  });
</script>
<template>
  <section class="block-slider">
    <h2 class="block-slider__header">{{ header }}</h2>
    <div class="block-slider__wrapper">
      <div class="block-slider__content" v-html="content" />
      <SfProductsCarousel :parameters :products />
    </div>
  </section>
</template>
<style lang="scss">
  :root {
    --sf-block-slider-background: var(--color-white);
  }
</style>
<style lang="scss" scoped>
  @use "$assets/mixins/media";

  .block-slider {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: var(--sf-block-slider-background);
    padding: 2rem;
    border-radius: var(--border-radius);

    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  @include media.query(lg) {
    .block-slider {
      padding: 1rem 0;
      gap: 0.75rem;

      &__content {
        font-size: 0.875rem;
        padding: 0 1rem;
      }

      &__header {
        padding: 0 1rem
      }

      &__wrapper {
        gap: 0.75rem;
      }
    }
  }
</style>
