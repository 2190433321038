<script lang="ts" setup>
  import { ref, watch, computed } from 'vue';
  import { useShared, route } from '@plenny/visitor';
  import { useBreakpoints, useScrollDetector } from '$storefront';

  const headerBottom = ref();

  const scroll = ref(false);
  const show = ref(false);
  const searchMobilebar = ref(false);

  const breakpoints = useBreakpoints();
  const shared = useShared();

  const phone = computed(() => {
    return shared.value.department?.phone;
  });

  const email = computed(() => {
    return shared.value.department?.email;
  });

  useScrollDetector({
    threshold: 8,
    callback: ({ up }) => {
      if (up) {
        headerBottom.value.classList.add('header__bottom--open');
        scroll.value = false;
      } else {
        headerBottom.value.classList.remove('header__bottom--open');
        scroll.value = true;
      }
    },
  });

  watch(scroll, () => {
    if (!scroll.value) {
      show.value = false;
      searchMobilebar.value = false;
    }
  });
</script>
<template>
  <header :class="{ 'header--scroll': scroll, 'search-mobile-active': searchMobilebar }" class="header">
    <div class="header__actions">
      <div class=" container header__actions-container">
        <div class="header__actions-contact">
          <a :href="`callto:${phone}`" class="header__actions-link">
            <IconPhone />
            {{ phone }}
          </a>
          <a :href="`callto:${email}`" class="header__actions-link">
            <IconEnvelope />
            {{ email }}
          </a>
        </div>
        <div class="header__actions-activities">
          <VatSwitcher />
          <LocalizationDropdown />
          <HeaderAccount />
        </div>
      </div>
    </div>
    <div class="header__top">
      <div class="container header__top-container">
        <VisitorLink :href="route('web.homepage.index')" class="header__top-logo">
          <SfLogo class="header__top-img" />
        </VisitorLink>

        <HeaderSearch v-if="breakpoints.lg" :placeholder="$t('Szukaj')" class="header__top-search" />
        <div class="header__top-icons">
          <HeaderFavorite v-if="breakpoints.lg" :class="{ scroll }" />
          <MiniCart />
        </div>
      </div>
    </div>
    <div ref="headerBottom" class="header__bottom header__bottom--open">
      <div class="container header__bottom-inner">
        <div class="header__bottom-wrapper">
          <HeaderSearch v-if="!breakpoints.lg" :label="$t('Szukaj')" :placeholder="$t('Szukaj')" />
          <Categories v-if="breakpoints.lg" compact />
          <nav v-if="breakpoints.lg" class="navigation">
            <ul>
              <HeaderNavItem v-for="item in shared.menu.menu_top" :depth="0" :item="item" />
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>
<style lang="scss" scoped>
  @keyframes show-overflow {
    from {
      overflow: hidden;
    }
    to {
      overflow: visible;
    }
  }

  .icon-hamburger {
    font-size: 1rem;
  }

  .search-mobile-active.header {
    box-shadow: none;
  }

  .mobile-search {
    position: fixed;
    top: 49px;
    width: 100%;
    z-index: 997;
    padding: 5px 15px 10px;
    background-color: var(--color-white);
    box-shadow: var(--primary-box-shadow-200);
  }

  .header {
    position: sticky;
    top: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    background: var(--color-white);
    box-shadow: var(--secondary-box-shadow-200);
    transition: var(--primary-transition);

    &__top-search {
      width: 50%;
    }

    &__actions {
      padding: 0.2rem 0;
      background: var(--color-light-background);

      @media (max-width: 992px) {
        display: none;
      }
    }

    &__actions-link,
    &__actions-container,
    &__actions-contact,
    &__actions-activities {
      display: flex;
      align-items: center;
    }

    &__actions-container {
      justify-content: space-between;
    }

    &__actions-contact {
      gap: 2rem;
    }

    &__actions-activities {
      gap: 2rem;
    }

    &__actions-link {
      gap: 0.5rem;
    }

    &__top {
      background-color: var(--color-grey-light);
    }

    &__top-container {
      position: relative;
      display: flex;
      justify-content: space-between;
      height: 90px;
      align-items: center;
      column-gap: 20px;
      padding: 1rem 0;
    }

    &__top-img {
      width: 100%;
      max-width: 250px;
      height: 50px;
    }

    &__top-icons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 30px;
      min-width: 260px;

      @media (max-width: 1200px) {
        min-width: 180px;
      }

      @media (max-width: 576px) {
        min-width: auto;
      }
    }
  }

  @media(max-width: 1400px) {
    .header {
      &__top-icons {
        column-gap: 20px;
      }
    }
  }

  @media(max-width: 1200px) {
    .header {
      &__top-logo, &__top-icons {
        width: auto;
      }
    }
  }


  .header__bottom {
    display: grid;
    width: 100%;
    min-width: 0;
    grid-template-rows: 0fr;
    transition: grid-template-rows ease-in-out 100ms;


    &--open {
      grid-template-rows: 1fr;

      .header__bottom-inner {
        animation: show-overflow ease-in-out 100ms;
        overflow: visible;
      }
    }
  }

  .header__bottom-inner {
    position: relative;
    display: flex;
    min-width: 0;
    overflow: hidden;
  }

  .header__bottom-wrapper {
    display: flex;
    width: 100%;
    min-width: 0;

    @media(max-width: 992px) {
      display: block;
      padding: 10px 15px;
    }
  }

  .navigation {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 60px;
    padding-left: 42px;
    width: 100%;

    ul {
      display: flex;
      gap: 3rem;
      padding: 0;
      margin: 0;
      list-style: none;

      li.nav-item {
        position: relative;

        :deep(> a) {
          font-weight: var(--font-weight-bold);
        }

        :deep(.nav-item-arrow) {
          transform: rotate(0);
          font-size: 0.75rem;
          margin-left: 0.5rem;
        }

        :deep(> .children-wrapper) {
          top: 100%;
          left: 0;
          right: unset;
          height: auto;
          padding: 0.5rem;
          min-width: 300px;
          border-radius: 0;

          a {
            font-weight: 600;
            padding: 0.5rem 0;
          }

          .nav-item.level-2 .children-wrapper {
            display: flex;
            flex-direction: column;
          }

          .children {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            padding-left: 1rem;
          }

          @media(max-width: 1200px) {
            left: unset;
            right: 0;
          }
        }
      }
    }

    :deep(.level-0) {
      & > a {
        text-decoration: none;
        line-height: 1.5;
        padding: 15px 0;
        color: var(--color-black);
        transition: var(--primary-transition);
        z-index: 2;
        font-weight: 500;


        &:not(.active):hover {
          color: var(--primary-color);
        }

        &.active {
          position: relative;
          display: inline-block;
          color: var(--primary-color);
          font-weight: 600;

          &:after {
            content: '';
            position: absolute;
            left: 0;
            transform: translateX(-45%);
            height: 22px;
            width: 22px;
            border-radius: 50%;
            background-color: var(--color-primary);
            z-index: -1;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .navigation ul {
      gap: 30px;
    }
  }

  @media (max-width: 992px) {
    .header {
      &__top-container {
        padding: 10px 15px;
        height: 50px;
      }

      &__top-img {
        width: 80px;
        transform: translateY(0.125rem);
      }

      &__top-icons {
        width: auto;
        gap: 1rem;
      }

      &__bottom-wrapper {
        background-color: var(--color-grey-light);
        padding: 0.25rem 1rem 0.5rem;
      }
    }

    .navigation {
      padding-left: 10px;

      ul {
          gap: 14px;
        font-size: 15px;
      }
    }
  }
</style>
