<script setup lang="ts">
  import { type PropType, computed } from 'vue';
  import type { Photo } from '@plenny/picture';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps({
    header: String,
    product: Object,
    photos: { type: Array as PropType<Photo[]>, required: true, default: [] },
  });

  const photoLink = computed(() => {
    return 'url(/photos/2720x1000/' + props.photos[0]?.filename + ')';
  });

</script>
<template>
  <section class="container special-offer-block">
    <SfHeading large type="h2" class="special-offer-block__heading">
      {{ header }}
    </SfHeading>
    <ProductCard v-if="product" :product horizontal class="special-offer-block__product" />
  </section>
</template>
<style scoped lang="scss">
  .special-offer-block {
    --image: v-bind(photoLink);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 5rem;
    background-image: var(--image);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    z-index: 0;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: -1;
    }

    &::before {
      left: 0;
      right: 60%;
      background-color: rgba(2, 30, 46, 0.75);
    }

    &::after {
      right: 0;
      left: 40%;
      background-color: rgba(242, 246, 247, 0.8);
    }

    &__product {

      :deep(.product__title) {
        font-weight: var(--font-weight-bold);
        font-size: 1.25rem;
      }

      :deep(.product__details) {
        font-size: 0.875rem;
        line-height: 1.3;
      }
    }

    h2 {
      color: var(--primary-foreground);
    }
  }

  @media(max-width: 992px) {
    .special-offer-block {
      padding: 3rem 1rem;
      gap: 1rem;
    }
  }
</style>
