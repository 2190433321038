<script setup lang="ts">
  import { computed } from 'vue';

  const variant = defineModel('variant', { type: Object, required: true });

  const props = defineProps({
    product: { type: Object, required: true },
    brand: Object,
    category: Object,
    page: Object,
  });

  const photos = computed(() => {
    return [
      ...variant.value.photos,
      ...props.product.photos,
      ...(props.product.gallery?.photos || []),
    ];
  });
</script>
<template>
  <div class="container product-details">
    <div class="product-details__gallery">
      <SfProductGallery v-bind="{ photos }" class="product-details__product-gallery" />
    </div>
    <div class="product-details__wrapper">
      <h1 class="product-details__title" v-html="variant.title" />
      <div class="product-details__details-container">
        <div class="product-details__description-wrapper">
          <div class="product-details__description" v-if="product.description_short" v-html="product.description_short" />
          <ShopProductViewMeta :brand :category :product :variant class="product-details__meta" />
        </div>
        <div class="product-details__actions-wrapper">
          <ShopProductViewTags :product :variant icon />
          <ShopProductViewActions :variant />
        </div>
        <div class="product-details__details-picker">
          <ShopProductViewPicker :product v-model:variant="variant" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  :root {
    --sf-product-gallery-view-details-button-width: 3rem;
    --sf-product-gallery-view-details-button-height: 3rem;
    --sf-product-gallery-view-details-button-border: var(--color-light-background);
    --sf-product-gallery-view-details-button-space: 0;

    --sf-product-gallery-view-details-button-border-radius: 0;
    --sf-product-gallery-view-details-button-box-shadow: var(--secondary-box-shadow-100);
    --sf-product-gallery-view-details-button-shadow-hover: var(--primary-box-shadow-100);
    --sf-product-gallery-view-details-button-background: var(--color-white);

    --sf-product-gallery-view-details-button-color-hover: var(--color-black);
    --sf-product-gallery-view-details-button-border-hover: var(--color-black);
    --sf-product-gallery-view-details-button-background-hover: var(--color-white);
    --sf-product-gallery-view-details-button-icon-size: 1.5rem;
  }

  .product-gallery .product-gallery__images {
    --sf-carousel-button-width: var(--sf-product-gallery-view-details-button-width);
    --sf-carousel-button-height: var(--sf-product-gallery-view-details-button-height);
    --sf-carousel-button-border: var(--sf-product-gallery-view-details-button-border);
    --sf-carousel-button-space: var(--sf-product-gallery-view-details-button-space);

    --sf-carousel-button-border-radius: var(--sf-product-gallery-view-details-button-border-radius);
    --sf-carousel-button-box-shadow: var(--sf-product-gallery-view-details-button-box-shadow);
    --sf-carousel-button-box-shadow-hover: var(--sf-product-gallery-view-details-button-shadow-hover);
    --sf-carousel-button-background: var(--sf-product-gallery-view-details-button-background);

    --sf-carousel-button-color-hover: var(--sf-product-gallery-view-details-button-color-hover);
    --sf-carousel-button-border-hover: var(--sf-product-gallery-view-details-button-border-hover);
    --sf-carousel-button-background-hover: var(--sf-product-gallery-view-details-button-background-hover);
    --sf-carousel-button-icon-size: var(--sf-product-gallery-view-details-button-icon-size);
  }
</style>
<style scoped lang="scss">
  .product-details {
    display: grid;
    grid-template-columns: 40% 1fr;
    gap: 5rem;

    &__gallery {
      display: flex;
    }

    &__product-gallery,
    &__gallery {
      width: 100%;
      max-width: 100%;
    }

    &__wrapper,
    &__description-wrapper,
    &__actions-wrapper {
      display: flex;
      flex-direction: column;
    }

    &__title {
      font-size: 1.25rem;
      line-height: 1.5;
      color: #074361;
    }

    &__wrapper {
      gap: 2rem;
    }

    &__details-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5rem;

      .product-details__description-wrapper :deep(.wrapper .info) {
        line-height: 1;
      }
    }

    &__description-wrapper {
      gap: 1rem;
    }

    &__actions-wrapper {
      gap: 1rem;
      justify-content: flex-end;
    }

    &__details-picker {
      width: 100%;
      grid-column: 1 / span 2;
    }
  }

  @media(max-width: 1400px) {
    .product-details {
      gap: 3rem;

      &__details-container {
        gap: 2rem;
      }
    }
  }

  @media(max-width: 1200px) {
    .product-details {
      &__details-container {
        grid-template-columns: 1fr;
      }

      &__details-picker {
        grid-column: unset;
      }
    }
  }

  @media(max-width: 992px) {
    .product-details {
      display: flex;
      flex-direction: column;
      padding: 0 1rem;
      gap: 2rem;

      &__description {
        font-size: 0.875rem;
      }

      &__details-picker :deep(.stock) {
        order: 1;
      }
    }
  }
</style>
