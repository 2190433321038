<script setup lang="ts">
  import { ref, type PropType, computed } from 'vue';
  import type { Photo } from '@plenny/picture';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps({
    header: String,
    content: String,
    name: String,
    link: String,
    products: Array,
    photos: { type: Array as PropType<Photo[]>, required: true, default: [] },
  });

  const photoLink = computed(() => {
    return 'url(/photos/2720x1000/' + props.photos[0]?.filename + ')';
  });

  const parameters = ref({
    breakpoints: {
      1: {
        slidesPerView: 1.5,
        slidesOffsetBefore: 16,
        spaceBetween: 16,
      },
      992: {
        slidesOffsetBefore: 0,
        spaceBetween: 32,
      },
      1200: {
        slidesPerView: 2,
      },
    },
  });
</script>
<template>
  <section class="chosen-category">
    <div class="chosen-category__text">
      <div class="chosen-category__text-wrapper">
        <SfHeading v-if="header" large type="h2">{{ header }}</SfHeading>
        <div v-html="content" />
      </div>
      <SfButton v-if="link" class="chosen-category__button" :href="link">
        {{ name }}
      </SfButton>
    </div>
    <div class="chosen-category__products">
      <SfProductsCarousel :products :parameters />
    </div>
  </section>
</template>
<style lang="scss" scoped>
  .chosen-category {
    --image: v-bind(photoLink);
    position: relative;
    display: grid;
    grid-template-columns: 1fr 60%;
    padding: 5rem;
    background-image: var(--image);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    z-index: 0;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: -1;
    }

    &::before {
      left: 0;
      right: 60%;
      background-color: rgba(2, 30, 46, 0.75);
    }

    &::after {
      right: 0;
      left: 40%;
      background-color: rgba(242, 246, 247, 0.8);
    }

    &__text-wrapper,
    &__text {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &__text-wrapper {
      --sf-heading-color: var(--primary-foreground)
    }

    &__text {
      gap: 3rem;
      color: var(--primary-foreground);
      padding-right: 5rem;
      line-height: 1.5;
    }

    &__button {
      align-self: flex-end;
    }

    &__products {
      width: 100%;
      max-width: 100%;

      --sf-carousel-button-color: var(--secondary-foreground);
      --sf-carousel-button-color-hover: var(--sf-carousel-button-color);
      --sf-carousel-button-background: var(--secondary-background);
      --sf-carousel-button-background-hover: var(--secondary-background);
      --sf-carousel-button-border-width: 1px;
      --sf-carousel-button-border: var(--secondary-border);
      --sf-carousel-button-border-hover: var(--secondary-border-hover);
    }
  }

  @media(max-width: 992px) {
    .chosen-category {
      display: flex;
      flex-direction: column;
      padding: 0 0 2rem;
      max-width: 100%;

      &__text {
        gap: 1rem;
        padding: 3rem 1rem 2rem
      }

      &__text-wrapper {
        gap: 1rem;
      }

      &::before,
      &::after {
        right: 0;
        left: 0;
      }

      &::before {
        bottom: 50%;
        top: 0;
      }

      &::after {
        top: 50%;
        bottom: 0;
      }
    }
  }
</style>