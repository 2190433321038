<script setup lang="ts">
  import { ref, type PropType } from 'vue';

  type Brand = {
    id: number;
    name: string;
    slug: string;
    photo: string | null;
  }

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: String,
    brands: { type: Array as PropType<Brand[]>, require: true },
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 4.5,
        slidesOffsetBefore: 16,
        spaceBetween: 16,
      },
      576: {
        slidesPerView: 5.5,
        slidesOffsetBefore: 16,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 6.5,
        slidesOffsetBefore: 16,
        spaceBetween: 16,
      },
      992: {
        slidesPerView: 7,
        slidesOffsetBefore: 0,
        spaceBetween: 24,
      },
    },
  });

</script>
<template>
  <Segment :title="header" :buttonText="$t('Zobacz wszystkie')" :link="route('web.brand.index')">
    <SfBrandsCarousel :brands :parameters />
  </Segment>
</template>
<style scoped lang="scss">
  .brands-carousel {
    padding: 0 5rem;

    :deep(img) {
      height: 75px;
      mix-blend-mode: multiply;    }
  }

  @media(max-width: 992px) {
    .brands-carousel {
      padding: 0;
    }
  }
</style>
