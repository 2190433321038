<script setup lang="ts">
  import { useBreakpoints } from '$storefront';
  import { computed, type PropType } from 'vue';
  import type { Photo } from '@plenny/picture';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: String,
    link: String,
    photos: { type: Array as PropType<Photo[]>, required: true },
  });

  const breakpoints = useBreakpoints();

  const photoType = computed(() => {
    return breakpoints.value.lg ? 'background_desktop' : 'background_mobile';
  });
</script>
<template>
  <section class="container block-banner" :class="{'block-banner--backdrop': header}" v-if="photos.length > 0">
    <VisitorLink :href="link || '#'" class="block-banner__link">
      <MediaPicture :photo="photos.filter(photo => photo.type === photoType)" :type="photoType" sizes="2700x1300 2x min 993, 1360x650 min 993, 1984x1984 2x, 992x992" />
    </VisitorLink>
    <h2 v-if="header" class="block-banner__heading">
      <span>
        {{ header }}
      </span>
    </h2>
  </section>
</template>
<style lang="scss">
  .block-banner {
    position: relative;

    &--backdrop::after {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      background: rgba(2, 30, 46, 0.75);
      pointer-events: none;
    }

    &__link {
      width: 100%;
      border: none;
    }

    &__heading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0 auto;
      font-size: 4rem;
      color: #F5F4F1;
      font-weight: var(--font-weight-bold);
      z-index: 1;
    }

    img {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1360/650;
    }
  }
</style>
