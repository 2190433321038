<script setup>
  import { ref } from 'vue';

  defineProps({
    breadcrumbs: { type: Array, required: false, default: [] },
    sections: Array,
    recommended: Array,
    post: Object,
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesOffsetBefore: 16,
        slidesPerView: 1.5,
        spaceBetween: 16,
      },
      992: {
        slidesPerView: 2,
        slidesOffsetBefore: 0,
        spaceBetween: 32,
      },
    },
  });
</script>
<template>
  <main class="container blog-show">
    <article>
      <div class="blog-show__wrapper">
        <SfBreadcrumbs :breadcrumbs />
        <div class="blog-show__content-wrapper">
          <div v-if="post.photos.filter(p => p.type === 'banner').length > 0" class="blog-show__image">
            <MediaPicture :photo="post.photos" sizes="1800x1000 2x, 900x500" type="banner" />
          </div>
          <header>
            <SfHeading class="blog-show__title" v-html="post.title" type="h1" />
            <div class="blog-show__subtitle" v-html="post.description_short" />
          </header>
          <div class="blog-show__data">
            <span v-if="post.date" class="blog-show__data-item">
              <IconCalender />
              <SfDateTime :value="post.date" />
            </span>
            <VisitorLink v-if="post.category" :href="route('web.blog.category.show', { category: post.category })" :title="post.category.title" class="blog-show__data-item">
              <IconSignet class="blog-show__icon-signet" />
              {{ post.category.title }}
            </VisitorLink>
            <span v-if="post.author" class="blog-show__data-item">
              <IconPencil />
              {{ post.author.name }}
            </span>
          </div>
          <SectionRenderer :sections />
        </div>
      </div>
      <div v-if="recommended.length > 0" class="blog-show__recommended">
        <div class="blog-show__recommended-header">

          <SfHeading class="blog-show__recommended-heading" type="h2">
            {{ $t('Polecane artykuły') }}
          </SfHeading>
        </div>
        <SfArticlesCarousel :parameters :posts="recommended" />
      </div>
    </article>
  </main>
</template>
<style lang="scss" scoped>
  .blog-show {
    --sf-text-block-padding: 0;

    &__content-wrapper {
      max-width: 1000px;
      margin: 0 auto 3rem;
    }

    &__title {
      margin: 3rem 0 1rem;
    }

    &__data {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 1rem 0 3rem
    }

    &__icon-signet {
      height: 1rem;
      width: 1rem;
    }

    &__data-item {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }

    &__recommended {

      .heading {
        border-top: 1px solid var(--secondary-border);
        padding: 2rem 5rem;
      }
    }
  }

  @media(max-width: 992px) {
    .blog-show {
      --sf-section-renderer-paragraph-font-size: 0.875rem;


      &__wrapper {
        padding: 0 1rem;
      }

      &__title {
        margin: 1rem 0;
        font-size: 1rem;
      }

      &__data {
        align-items: flex-start;
        flex-direction: column;
        gap: 0.5rem;
        margin: 1rem 0 3rem;
        font-size: 0.75rem;
      }

      &__recommended-header {
        padding: 0 1rem;
      }

      &__recommended .heading {
        padding: 1.5rem 0;
      }
    }
  }
</style>
